<template>
  <div :key="`sites/${siteFilter}`">
    <algolia-site-list
      :site-filter="siteFilter"
      :route-func="
        hit => ({ path: `/admin/sites/${siteFilter}/${hit.siteId}` })
      "
    >
      <template slot="nosearch" slot-scope="{ isEmpty }">
        <list
          v-show="isEmpty"
          :reference="reference"
          :sorters="sorters"
          :active-sort="activeSort"
        >
          <template slot-scope="site">
            <list-card
              :title="`${site.item.fqdn}`"
              :subtitle-dark="`Software: ${site.item.software}`"
              :label="site.item.isDeleted ? 'deleted' : 'active'"
              :timestamp="site.item.dateCreated.toDate()"
              :route="{ path: `/admin/sites/${siteFilter}/${site.item._id}` }"
            />
          </template>
        </list>
      </template>
    </algolia-site-list>
  </div>
</template>

<script>
import { collection, query, where } from "@firebase/firestore";
export default {
  name: "AdminSites",
  components: {
    "algolia-site-list": () => import("@shared/sites/_algoliaSiteList.vue")
  },
  data() {
    return {
      sorters: [
        {
          field: "dateCreated",
          label: "Date Created"
        }
      ],
      activeSort: {
        field: "dateCreated",
        direction: "desc"
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    reference() {
      const allSitesRef = collection(this.$firestore, "sites");
      switch (this.siteFilter) {
        case "all":
          return allSitesRef;
        case "subscribed":
          return query(allSitesRef, where("isSubscribed", "==", true));
        case "my":
          return query(
            allSitesRef,
            where("agents", "array-contains", this.userId)
          );
      }
      return allSitesRef;
    },
    siteFilter() {
      return this.$route.params.siteFilter;
    }
  }
};
</script>
